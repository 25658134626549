.form-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e9ecef;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  padding-top: 20px;
  > .btn {
    width: 140px;
    margin: 0 20px;
  }
}
