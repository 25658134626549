@import "customize.scss";

@mixin node($bg-color, $border-color, $border-style) {
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 50%;
  background-color: $bg-color;
  border-color: $border-color;
  border-width: 1px;
  border-style: $border-style;

  &.active-node {
    box-shadow: 0 0 2px 2px $bg-color;
    border-width: 2px;
  }

  &:hover {
    box-shadow: 0 0 2px 2px $bg-color;
  }
}

.tree-board {
  @include board-border;
  min-height: 200px;
  height: 25vh;
}

.react-flow__node-default {
  &.root-node {
    @include node($root-color, #195a8a, dashed);
  }

  &.unit-node {
    @include node($unit-color, #195a8a, solid);
  }

  &.active-node {
    transition: all 0.1s;
  }
}

.entrance-board {
  @include board-border;
}

.user-data-row {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: #ddd;
    box-shadow: 0 0 0 2px rgba(53, 90, 146, 0.25);
    border-radius: 5px;
  }

  .user-avatar {
    width: 50px;
    text-align: center;
    position: relative;
    top: 16px;

    > span {
      color: #444;
      font-size: 14px;
      font-weight: bold;
    }

    &::after {
      content: " ";
      display: inline-block;
      border-top: 1px dashed #888;
      position: absolute;
      width: 50px;
      top: 15px;
      left: 50px;
    }
  }

  .user-flow {
    height: 120px;
    width: 100%;
  }
}
