@import "customize.scss";

.unit {
  background-color: $unit-color;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
}

.root {
  background-color: $root-color;

  &:hover {
    cursor: pointer;
  }
}

.hyperlink {
  user-select: none;
  text-decoration: 2px underline $unit-color;
  text-decoration-style: dashed;
  text-decoration-thickness: 3px;

  &:hover {
    cursor: pointer;
  }
}
