body {
  margin: 0;
  overflow-x: hidden;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import "~bootstrap/scss/bootstrap.scss";

.btn:disabled {
  cursor: not-allowed;
}
