@import "customize.scss";

.button-container {
  padding: 0 15px;

  &.show {
    display: block;
  }

  &.hide {
    display: none;
  }
}

.story-board {
  @include board-border;
  min-height: 50vh;

  div[contenteditable="true"] {
    font-size: 16px;
    width: 100%;
    height: 100%;
    resize: unset;
    border: none;
    outline: none;
  }

  &.show {
    display: block;
  }

  &.hide {
    display: none;
  }

  &.editable.editable {
    &::before {
      display: none;
    }
  }
}
