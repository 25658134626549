header {
  display: flex;
  justify-content: space-between;
  padding: 12px 60px 0 59px;
  align-items: center;

  h1 {
    font-style: italic;
    color: #087025;
    margin-left: 0;
  }

  span {
    font-size: 18px;
  }

  .userName {
    font-weight: bold;
    padding-right: 8px;
  }
}

.entrance,
.main {
  display: block;
  margin: 40px;

  &.show {
    display: block;
  }

  &.hide {
    display: none;
  }

  &.invisible {
    visibility: hidden;
  }
}

.mask .story-board::before {
  content: " ";
  display: block;
  min-height: 50vh;
  width: calc(100% - 112px);
  cursor: not-allowed;
  position: absolute;
  background: #c9d4dd4a;
  top: 400px;
  left: 56px;
}

.entrance {
  position: absolute;
  width: 95%;
  z-index: 999;
}

.btn-forest {
  position: absolute;
  top: 132px;
  left: 55px;
  z-index: 999;

  button {
    color: #626262;
    border-radius: 5px 0;
  }
}

.user-story-tip {
  position: relative;
  margin-left: 60px;
  top: 20px;
  font-weight: bold;
}
