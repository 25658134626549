.story-popup {
  position: absolute;
  background: #fff;
  white-space: nowrap;

  > .btn {
    margin-right: 1px;
    border-right: 1px solid #888;

    > svg {
      margin-right: 5px;
    }

    &.btn-primary svg {
      transform: rotate(90deg);
    }

    &.btn-secondary svg {
      font-size: 14px;
    }

    &.btn-add-link {
      background-color: #429f44;
      border-color: #429f44;
      svg {
        transform: rotate(90deg);
      }
    }
  }

  &.show {
    display: block;
  }

  &.hide {
    display: none;
  }
}
