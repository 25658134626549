.user-link-item {
  cursor: pointer;
  &:hover {
    background-color: #21252924;
    border-radius: 3px;
  }

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid transparent;
    margin: 8px 0;
    padding: 4px 0;

    svg {
      transform: rotate(-45deg);
    }

    > span {
      display: inline-block;
      margin-left: 2px;
    }

    > span:last-child {
      margin-left: 8px;
    }
  }

  .user-root-item {
    > span:last-child {
      text-decoration: 2px underline #5fce7c;
      text-decoration-style: dashed;
      text-decoration-thickness: 3px;
    }
  }

  .user-unit-item {
    > span:last-child {
      text-decoration: 2px underline #6fbef7;
      text-decoration-style: dashed;
      text-decoration-thickness: 3px;
    }
  }
}
